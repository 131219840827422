import React from "react";
import { connect } from "react-redux";
import { subscribe } from "../../../actions/index";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        email: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  handleSubmit(event) {
    event.preventDefault();
    // get action
    const subscribeAction = subscribe(this.state);

    // Dispatch the contact from data
    this.props.dispatch(subscribeAction);

    // added delay to change button text to previous
    setTimeout(
      function() {
        // get action again to update state
        const subscribeAction = subscribe(this.state);

        // Dispatch the contact from data
        this.props.dispatch(subscribeAction);

        // clear form data
        this.setState({
          email: ""
        });
      }.bind(this),
      3000
    );
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
    {/*    <footer className={"footer-1 bg-light ptb-60 " + (this.props.withoutNewsletter && this.props.withoutNewsletter  === true ? "" : "footer-with-newsletter" )}>*/}
    {/*    {!(this.props.withoutNewsletter && this.props.withoutNewsletter  === true ) && (*/}
    {/*    <div className="container">*/}
    {/*        <div className="row newsletter-wrap primary-bg rounded shadow-lg p-5">*/}
    {/*            <div className="col-md-6 col-lg-7 mb-4 mb-md-0 mb-sm-4 mb-lg-0">*/}
    {/*                <div className="newsletter-content text-white">*/}
    {/*                    <h3 className="mb-0 text-white">Subscribe our Newsletter</h3>*/}
    {/*                    <p className="mb-0">We’re a team of non-cynics who truly care for our work.</p>*/}
    {/*                </div>*/}
    {/*            </div>*/}
    {/*            <div className="col-md-6 col-lg-5">*/}
    {/*                <form className="newsletter-form position-relative" method="post" onSubmit={this.handleSubmit}>*/}
    {/*                    <input value={this.state.email} onChange={e => this.handleFormValueChange("email", e)} type="text" className="input-newsletter form-control" placeholder="Enter your email" name="email" required="" />*/}
    {/*                    <button type="submit" className="disabled"><i className="fas fa-paper-plane"></i></button>*/}
    {/*                </form>*/}
    {/*            </div>*/}
    {/*        </div>*/}
    {/*    </div>*/}
    {/*    )}*/}

    {/*    <div className="container">*/}
    {/*        <div className="row">*/}
    {/*            <div className="col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0">*/}
    {/*                <a href="/#" className="navbar-brand mb-2">*/}
    {/*                    <img src="assets/img/icares/logo.png" alt="logo" className="img-fluid" />*/}
    {/*                </a>*/}
    {/*                <br />*/}
    {/*                <p>Technology solutions that serve the entire community by improving resident wellness and happiness, reducing staff turnover, connecting families, and increasing occupancy.</p>*/}
    {/*                <div className="list-inline social-list-default background-color social-hover-2 mt-2">*/}
    {/*                    <li className="list-inline-item"><a className="facebook" href="https://www.facebook.com/iCARESCommunity" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a></li>*/}
    {/*                </div>*/}
    {/*            </div>*/}
    {/*        </div>*/}
    {/*    </div>*/}
    {/*</footer>*/}
    <div className="footer-bottom py-3 gray-light-bg">
        <div className="container">
            <div className="row">
                <div className="col-md-6 col-lg-7">
                    <div className="copyright-wrap small-text">
                        <p className="mb-0">&copy; iCARES Technology Sdn. Bhd. (1420452-T)</p>
                    </div>
                </div>
                <div className="col-md-6 col-lg-5">
                    <div className="terms-policy-wrap text-lg-right text-md-right text-left">
                        <ul className="list-inline">
                            {/*<li className="list-inline-item"><a className="small-text" href="/#">Terms</a></li>*/}
                            {/*<li className="list-inline-item"> |  </li>*/}
                            <li className="list-inline-item"><a className="small-text" href="/privacy-policy">Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(Footer);
