import React from "react";
import { connect } from "react-redux";
import _data from "../../../data";

class Usp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        promo: {},
        items: [
            { title: 'Always Alert', imageSrc: 'assets/img/icares/usp/alert.png' },
            { title: 'Smart Barrier Access & Monitoring', imageSrc: 'assets/img/icares/usp/smartbarrier.svg' },
            { title: 'Intelligent Visitor Tracking', imageSrc: 'assets/img/icares/usp/visitortracking.svg' },
            { title: 'Face Recognition & Smart Phone Access', imageSrc: 'assets/img/icares/usp/facerecognition.png' },
            { title: 'Smart Lift Access', imageSrc: 'assets/img/icares/usp/smartlift.svg' },
            { title: 'Identify Before Clamping', imageSrc: 'assets/img/icares/usp/identify.svg' },
        ],
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      promo: _data.promo
    });
  }

  renderItems() {

  }

  render() {
    return (
      <React.Fragment>
        <section id="usp" className="promo-section ptb-100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-10 col-lg-8">
                        <div className="section-heading text-center">
                            <h2>Your Trusted Security Solutions for Your Community</h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-md-center justify-content-sm-center">
                    {this.state.items.map((i) => {
                        return <div className="col-md-6 col-lg-4">
                            <div className="card border-0 single-promo-card single-promo-hover text-center p-2 mt-4">
                                <div className="card-body">
                                    <div className="pb-2">
                                        <img src={ i.imageSrc } alt={ i.title } style={{ width: '45%' }} />
                                    </div>
                                    <div className="pt-2 pb-3" style={{ height: '85px' }}>
                                        <h5>{ i.title }</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(Usp);
