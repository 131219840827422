  module.exports = {
    download: {
      apple: { url: 'https://apps.apple.com/my/app/icares-community-app/id1472689576' },
      google: { url: 'https://play.google.com/store/apps/details?id=community.icares.app' },
      huawei: { url: 'https://appgallery.huawei.com/app/C102144447' },
    },

    contact: {
      title: "Looking for a excellent Business idea?",
      description: "Give us a call or drop by anytime, we endeavour to answer all enquiries within 24 hours on business days.",
      addressTitle: "Address (Head Office)",
      addressKLTitle: "Address (KL Office)",
      addressIcon: "fas fa-location-arrow",
      address: "15, Jalan Eko Perniagaan 2/4, Taman Eko Perniagaan, 81100, Johor Bahru",
      addressKL: "108 3A, Jalan PJU 1/3B, Sunwaymas Commercial Cente, 47301 Petaling Jaya, Selangor",
      emailTitle: "Email (Head Office)",
      emailKLTitle: "Email (KL Office)",
      emailIcon: "fas fa-envelope",
      email: "hello@icares.community",
      emailKL: "hello.kl@icares.community",
      phoneTitle: "Phone (Head Office)",
      phoneKLTitle: "Phone (KL Office)",
      phoneIcon: "fas fa-phone",
      phone: "+6019-9920707",
      phoneKL: "+6014-9523834"
    }
  };
