import React from "react";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <div className="overflow-hidden">
            <section id="about" className="about-us ptb-100 background-shape-img position-relative">
                <div className="animated-shape-wrap">
                    <div className="animated-shape-item"></div>
                    <div className="animated-shape-item"></div>
                    <div className="animated-shape-item"></div>
                    <div className="animated-shape-item"></div>
                    <div className="animated-shape-item"></div>
                </div>
                <div className="container">
                    <div className="row align-items-center justify-content-lg-between justify-content-md-center justify-content-sm-center">
                        <div className="col-md-12 col-lg-6 mb-5 mb-md-5 mb-sm-5 mb-lg-0">
                            <div className="about-content-left">
                                <h2>Our trusted smart IoT Solutions</h2>
                                <ul className="dot-circle pt-3">
                                    <li>Automated Number Plate Recognition System (ANPR)</li>
                                    <li>Total more than 5,000,000 vehicle from 2020 February to 2021 October.</li>
                                    <li>Verified More than 1,200,000 times Visitors Checked-in & Out.</li>
                                </ul>

                                <div className="row pt-3">
                                    <div className="col-6 col-lg-5 border-right">
                                        <div className="count-data text-center">
                                            <h4 className="count-number mb-0 color-primary font-weight-bold">532,189</h4>
                                            <span><strong>Verified</strong><br /><small>/ per month</small></span>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-5">
                                        <div className="count-data text-center">
                                            <h4 className="count-number mb-0 color-primary font-weight-bold">468,522</h4>
                                            <span>
                                                <strong>Checked In & Out</strong>
                                                <br />
                                                <small>/ every 6 months</small>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-5 col-md-5 col-lg-5">
                            <div className="about-content-right">
                                <img src="assets/img/icares/home/anpr-camera.png" alt="Smart IoT" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
      </React.Fragment>
    );
  }
}

export default About;
