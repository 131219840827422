import React from "react";
import _data from "../../../data";

class TrustedCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trustedCompany: [],
        items: [
            { title: 'Customer', imageSrc: 'assets/img/icares/customers/Picture1.jpeg' },
            { title: 'Customer', imageSrc: 'assets/img/icares/customers/Picture2.png' },
            { title: 'Customer', imageSrc: 'assets/img/icares/customers/Picture3.png' },
            { title: 'Customer', imageSrc: 'assets/img/icares/customers/Picture4.png' },
            { title: 'Customer', imageSrc: 'assets/img/icares/customers/Picture5.png' },
            { title: 'Customer', imageSrc: 'assets/img/icares/customers/Picture6.png' },
            { title: 'Customer', imageSrc: 'assets/img/icares/customers/Picture7.png' },
            { title: 'Customer', imageSrc: 'assets/img/icares/customers/Picture8.png' },
            { title: 'Customer', imageSrc: 'assets/img/icares/customers/Picture9.png' },
        ],
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      trustedCompany: _data.trustedCompanyAboutUs
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className="client-section ptb-100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="section-heading text-center mb-5">
                            <h2>Our Valuable Customers</h2>
                            <p>Taking Care Of Everyone Of The Communities, Residentials, Schools, Industrials, Office Towers.</p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-md-12">
                        <div className="owl-carousel owl-theme clients-carousel dot-indicator">
                            {this.state.items.map((i) => {
                                return <div className="item single-customer">
                                    <img src={ i.imageSrc } alt={ i.title } className="customer-logo" />
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </React.Fragment>
    );
  }
}

export default TrustedCompany;
