import React from "react";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section className="position-relative feature-section ptb-100">
            <div className="container">
                <div className="row align-items-center justify-content-between justify-content-sm-center justify-content-md-center">
                    <div className="col-sm-5 col-md-6 col-lg-6 mb-5 mb-md-5 mb-sm-5 mb-lg-0">
                        <div className="download-img">
                            <img src="assets/img/icares/home/smart-feature.png" alt="Smart Features" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <div className="feature-contents">
                            <h2>Managed your properties with iCARES Smart Community</h2>
                            <ul className="dot-circle pt-2">
                                <li>Intelligence Visitors control</li>
                                <li>Smart Access Solutions</li>
                                <li>Communication Solution</li>
                                <li>Bill Payment</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </React.Fragment>
    );
  }
}

export default About;
