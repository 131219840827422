import React from "react";
import { connect } from "react-redux";

class Counter extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="counter-section bg-dark ptb-40">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-3">
                        <div className="text-white p-2 count-data text-center my-3">
                            <span className="fas fa-users icon-size-lg mb-2"></span>
                            <h3 className=" mb-1 text-white font-weight-bolder">Residential</h3>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3">
                        <div className="text-white p-2 count-data text-center my-3">
                            <span className="fas fa-industry icon-size-lg mb-2"></span>
                            <h3 className=" mb-1 text-white font-weight-bolder">Industry</h3>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3">
                        <div className="text-white p-2 count-data text-center my-3">
                            <span className="fas fa-graduation-cap icon-size-lg mb-2"></span>
                            <h3 className=" mb-1 text-white font-weight-bolder">Educational</h3>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3">
                        <div className="text-white p-2 count-data text-center my-3">
                            <span className="fas fa-building icon-size-lg mb-2"></span>
                            <h3 className=" mb-1 text-white font-weight-bolder">Office Tower</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({}))(Counter);
