import React, { Component } from "react";

import Hero from "../components/icares/Home/HeroSection";
import AboutUs from "../components/icares/Home/AboutUs";
import AboutUsExt from "../components/icares/Home/AboutUsExt";
import Usp from "../components/icares/Home/Usp";
import Features from "../components/icares/Home/Features";
import WorkProcess from "../components/icares/WorkProcess";
import Screenshots from "../components/icares/Screenshots";
import TrustedCompany from "../components/icares/TrustedCompany/TrustedCompany";
import Counter from "../components/icares/CallToAction/Counter";
import Download from "../components/icares/CallToAction/Download";
import Footer from "../components/icares/Footer";
import Contact from "../components/icares/Contact";

import Header from "../components/Header/header";

class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="main">
          <Hero />
          <Usp />
          <AboutUs />
          <AboutUsExt />
          <Counter />
          <Features />
          <Screenshots />
          <WorkProcess />

          <TrustedCompany />
          <Download />
          <Contact />
        </div>
        <Footer withoutNewsletter={ true } />
      </React.Fragment>
    );
  }
}

export default Home;
