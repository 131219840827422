import React from "react";
import { connect } from "react-redux";
import _data from "../../../data";

class Screenshots extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      price: {},
        items: [
            { title: 'App Screen', imageSrc: 'assets/img/icares/screenshots/AppScreenshot-2.jpg' },
            { title: 'App Screen', imageSrc: 'assets/img/icares/screenshots/AppScreenshot-3.jpg' },
            { title: 'App Screen', imageSrc: 'assets/img/icares/screenshots/AppScreenshot-4.jpg' },
            { title: 'App Screen', imageSrc: 'assets/img/icares/screenshots/AppScreenshot-5.jpg' },
            { title: 'App Screen', imageSrc: 'assets/img/icares/screenshots/AppScreenshot-6.jpg' },
        ],
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      price: _data.price
    });
  }

  render() {
    return (
      <React.Fragment>
        <section id="screenshots" className={"screenshots-section pb-100 " + (this.props.removeTop && this.props.removeTop === true ? '' : 'pt-100') + (this.props.isWhite && this.props.isWhite === true ? '' : ' gray-light-bg')}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-9 col-lg-8">
                        <div className="section-heading text-center mb-5">
                            <h2>Apps Screenshots</h2>
                            <p>Come with Multiple languages, user-friendly UI Design & user guides</p>
                        </div>
                    </div>
                </div>
                <div className="screenshot-wrap">
                    <div className="screenshot-frame"></div>
                    <div className="screen-carousel owl-carousel owl-theme dot-indicator">
                        {this.state.items.map((i) => {
                            return <img src={ i.imageSrc } alt={ i.title } className="img-fluid" />
                        })}
                    </div>
                </div>
            </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(Screenshots);
