/**
 * Copyright 2022 - present, Alpstein Technology Sdn Bhd.
 * All rights reserved.
 */

import React from "react";
import _ from 'lodash';

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      htmlContent: ""
    };
  }

  componentDidMount() {
    fetch("https://api.icares.app/static-page/term_of_service")
      .then(response => response.json())
      .then(data => {

        const dataItem = _.get(data, ["items"]);

        if (!_.isEmpty(dataItem)) {

          this.setState({
            htmlContent: _.get(_.head(dataItem), ["page","content"])
          })
        } else {
          this.setState({
            htmlContent: "Content is not available. Please try again."
          })
        }
      })
      .catch(error => {
        this.setState({
          htmlContent: `Failed to fetch: ${error}`
        })
      });
  }

  render() {
    const { htmlContent } = this.state;

    return (
      <React.Fragment>
        <div className="overflow-hidden" style={{display:"flex", flex:1, flexDirection:'column', paddingTop:15, paddingBottom:15, paddingRight: 25, paddingLeft:25}}>
          <div dangerouslySetInnerHTML={{ __html: htmlContent }}>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PrivacyPolicy;
