import React from "react";
import { connect } from "react-redux";

class Header extends React.Component {
  render() {
    return (
      <React.Fragment>
        <header className="header">
          <nav className="navbar navbar-expand-lg fixed-top bg-transparent">
              <div className="container">
                  <a className="navbar-brand" href="/" style={{ width: '20%' }}>
                      <img src="assets/img/icares/logo-white.png" alt="logo" className="img-fluid" />
                  </a>
                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                      <span className="ti-menu"></span>
                  </button>

                  <div className="collapse navbar-collapse h-auto" id="navbarSupportedContent">
                      <ul className="navbar-nav ml-auto menu">
                          <li><a href="/">Home</a></li>
                          <li><a href="#usp" className="page-scroll">About</a></li>
                          <li><a href="#features" className="page-scroll">Features</a></li>
                          <li><a href="#download" className="page-scroll">Download</a></li>
                          <li><a href="#contact" className="page-scroll">Contact Us</a></li>
                      </ul>
                  </div>
              </div>
          </nav>
      </header>
      </React.Fragment>
    );
  }
}

export default connect(state => ({}))(Header);
