import React from "react";
import { connect } from "react-redux";
import _data from '../../../data';

class Download extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            download: {
              apple: { url: 'https://apps.apple.com/my/app/icares-community-app/id1472689576' },
              google: { url: 'https://play.google.com/store/apps/details?id=community.icares.app' },
              huawei: { url: 'https://appgallery.huawei.com/app/C102144447' },
            },
        };
    }

  componentDidMount() {
      this.setState({
          download: _data.download,
      });
  }

  render() {
    return (
      <React.Fragment>
        <section id="download" className="bg-image ptb-100" image-overlay="6">
            <div className="background-image-wraper" style={{
              backgroundImage: "url(assets/img/icares/download/bg.jpg)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom center",
              backgroundSize: "cover fixed",
              opacity: 1
            }}></div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-9 col-lg-8">
                        <div className="section-heading text-center mb-1 text-white">
                            <h2 className="text-white">Download Our Apps</h2>
                            <p>Bring your community a brand new living experience and safer solutions.</p>
                            <div className="action-btns mt-4">
                                <ul className="list-inline">
                                    <li className="list-inline-item">
                                        <a href={ this.state.download.apple.url } target="_blank" rel="noopener noreferrer" className="d-flex align-items-center" style={{ width: '200px' }}>
                                            <img src="assets/img/icares/download/appstore.png" alt="Apple Store" className="img-fluid" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href={ this.state.download.google.url } target="_blank" rel="noopener noreferrer" className="d-flex align-items-center" style={{ width: '200px' }}>
                                            <img src="assets/img/icares/download/google.png" alt="Google Play Store" className="img-fluid" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href={ this.state.download.huawei.url } target="_blank" rel="noopener noreferrer" className="d-flex align-items-center" style={{ width: '200px' }}>
                                            <img src="assets/img/icares/download/huawei.png" alt="Huawei App Gallery" className="img-fluid" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({}))(Download);
