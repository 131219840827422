import React from "react";
import { connect } from "react-redux";
import _data from "../../../data";

class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        download: {
            apple: { url: 'https://apps.apple.com/my/app/icares-community-app/id1472689576' },
            google: { url: 'https://play.google.com/store/apps/details?id=community.icares.app' },
            huawei: { url: 'https://appgallery.huawei.com/app/C102144447' },
        },
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
        download: _data.download
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className="position-relative bg-image pt-100" style={{ backgroundColor: "#000000" }}>
            <div className="background-image-wraper"></div>
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-md-10 col-lg-6">
                        <div className="section-heading text-white py-5">
                            <h1 className="text-white font-weight-bold">Simple • Secure •<br />Yet Convenient</h1>
                            <p>Malaysia’s first most innovative property
                                management platform with Smart IoT technology
                                & designed for high rise building as well as gated
                                guarded communities</p>
                            <div className="action-btns mt-4">
                                <ul className="list-inline" style={{ display: 'flex' }}>
                                    <li className="list-inline-item">
                                        <a href={ this.state.download.apple.url } target="_blank" rel="noopener noreferrer" className="d-flex align-items-center app-download-btn btn btn-white btn-rounded">
                                            <img src="assets/img/icares/download/appstore.png" alt="Apple Store" className="img-fluid" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href={ this.state.download.google.url } target="_blank" rel="noopener noreferrer" className="d-flex align-items-center app-download-btn btn btn-white btn-rounded">
                                            <img src="assets/img/icares/download/google.png" alt="Google Play Store" className="img-fluid" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href={ this.state.download.huawei.url } target="_blank" rel="noopener noreferrer" className="d-flex align-items-center app-download-btn btn btn-white btn-rounded">
                                            <img src="assets/img/icares/download/huawei.png" alt="Huawei App Gallery" className="img-fluid" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-9 col-lg-5">
                        <div className="img-wrap">
                            <img src="assets/img/icares/home/hand-hold-app.jpg" alt="shape" className="img-fluid" />
                        </div>
                    </div>
                </div>

                {/*<div className="row mb-5">*/}
                {/*    <ul className="list-inline counter-wrap bg-white">*/}
                {/*        <li className="list-inline-item">*/}
                {/*            <div className="single-counter text-center count-data">*/}
                {/*                <span className="color-primary count-number">2350</span>*/}
                {/*                <h6>Happy Client</h6>*/}
                {/*            </div>*/}
                {/*        </li>*/}
                {/*        <li className="list-inline-item">*/}
                {/*            <div className="single-counter text-center count-data">*/}
                {/*                <span className="color-primary count-number">2150</span>*/}
                {/*                <h6>App Download</h6>*/}
                {/*            </div>*/}
                {/*        </li>*/}
                {/*        <li className="list-inline-item">*/}
                {/*            <div className="single-counter text-center count-data">*/}
                {/*                <span className="color-primary count-number">2350</span>*/}
                {/*                <h6>Total Rates</h6>*/}
                {/*            </div>*/}
                {/*        </li>*/}
                {/*        <li className="list-inline-item">*/}
                {/*            <div className="single-counter text-center count-data">*/}
                {/*                <span className="color-primary count-number">2250</span>*/}
                {/*                <h6>Awards win</h6>*/}
                {/*            </div>*/}
                {/*        </li>*/}
                {/*    </ul>*/}
                {/*</div>*/}
            </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(HeroSection);
